<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="serverDataLoaded"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Change Status</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="row ma-0">
          <div class="h-75px py-0 col-12 col-sm-6">
            <v-select
              v-model="formData.status_id"
              label="Status"
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              clearable
              outlined
              dense
              :error-messages="status_idErrors"
              @input="$v.formData.status_id.$touch()"
              @blur="$v.formData.status_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px py-0 col-12 col-sm-6">
            <v-datetime-picker
              ref="datetime"
              label="Date"
              :textFieldProps="{
                outlined: true,

                dense: true,
                'prepend-icon': 'mdi-calendar',
              }"
              v-model="formData.date"
              :datePickerProps="{}"
              :timePickerProps="{}"
            >
            </v-datetime-picker>
          </div>

          <div class="py-0 col-12">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div
            class="py-0 col-12 col-sm-6"
            v-if="!isMultiple && formData.status_id == '50'"
          >
            <v-text-field
              v-model="formData.total_weight"
              label="Weight"
              type="number"
              min="0"
              clearable
              outlined
              dense
              @change="() => validateMinValue('total_weight', 0)"
            ></v-text-field>
          </div>
          <div
            class="py-0 col-12 col-sm-6"
            v-if="!isMultiple && formData.status_id == '50'"
          >
            <v-text-field
              v-model="formData.total_volume"
              label="Volume"
              type="number"
              min="0"
              clearable
              outlined
              dense
              @change="() => validateMinValue('total_volume', 0)"
            ></v-text-field>
          </div>
          <div
            class="py-0 col-12 col-sm-6"
            v-if="!isMultiple && formData.status_id == '50'"
          >
            <v-text-field
              v-model="formData.number_of_boxes"
              label="Number of boxes"
              type="number"
              min="0"
              clearable
              outlined
              dense
              @change="() => validateMinValue('number_of_boxes', 0)"
            ></v-text-field>
          </div>

          <!--end::Body-->
        </div>
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
// import {
//   SET_REPORT_MODAL_STATE,
//   SET_REPORT_MODAL_DATA,
// } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  mixins: [validationMixin, fieldValueValidation],
  components: {},
  validations: {
    formData: {
      status_id: { required },
    },
  },
  name: "ChangeStatus",
  props: ["refresher", "pageLoader", "ids", "isMultiple"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      status_id: null,
      date: new Date(),
      description: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/shipping/pickup_orders/update_pickup_status")
        .then((response) => {
          this.serverData = response.data;
          this.$v.$touch();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = { ...this.formData };
      if (Array.isArray(this.ids)) {
        // data.ids = this.ids.map((item) => item.systemId);
        data.ids = this.ids;
      } else {
        data.ids = [this.ids];
      }
      if (data.date && data.date !== "") {
        data.date = this.dateFormatter(this.formData.date);
      }
      ApiService.post("/shipping/pickup_orders/update_pickup_status_save", data)
        .then(({ data }) => {
          // this.$store.commit(SET_REPORT_MODAL_DATA, response.data.messages);
          // this.$store.commit(SET_REPORT_MODAL_STATE, true);
          if (data.hasOwnProperty("error_orders")) {
            let editedItemMessage = `<div><ol>`;
            data.messages.forEach((item) => {
              editedItemMessage += `<li>${item}</li>`;
            });
            editedItemMessage = editedItemMessage + `</ol></div>`;
            Swal.fire({
              title: `Result`,
              html: editedItemMessage,
              icon: data.error_orders === 0 ? "success" : "warning",
              showConfirmButton: true,
            });
          } else swalEdited();
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.$refs.datetime.clearHandler();
      this.formData = {
        status_id: null,
        date: new Date(),
        description: null,
      };
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    status_idErrors: function () {
      return this.handleFormValidation("status_id", this);
    },
  },
};
</script>
