<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    "
  >
    <form id="createForm" class="pa-3 mx-3 row elevation-0">
      <div class="col-12 h-75px">
        <v-combobox
          v-model="formData.order_ids"
          label="Orders"
          outlined
          dense
          @change="handleChangeMultiple"
          multiple
          deletable-chips
          clearable
          allow-overflow
          small-chips
        />
      </div>

      <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.destination_address_type"
          label="Destination address type"
          :items="serverData.destination_address_types"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="destination_address_type_idErrors"
          @input="$v.formData.destination_address_type.$touch()"
          @blur="$v.formData.destination_address_type.$touch()"
        ></v-select>
      </div>

      <div
        class="col-12 col-md-6 h-75px"
        v-if="formData.destination_address_type === 0"
      >
        <v-select
          v-model="formData.destination_country_id"
          label="Destination Country"
          :items="serverData.countries"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="destination_country_Errors"
          @input="$v.formData.destination_country_id.$touch()"
          @blur="$v.formData.destination_country_id.$touch()"
        ></v-select>
      </div>

      <div
        v-if="formData.destination_address_type === 1"
        class="col-12 col-md-6 h-75px"
      >
        <v-select
          v-model="formData.warehouse_id"
          label="Destination Warehouse"
          :items="serverData.warehouses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="destination_address_Errors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div>

      <div class="col-12 col-md-6 h-75px">
        <v-autocomplete
          v-model="formData.collection_address_id"
          label="Collection address"
          :items="collection_addresses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="collection_address_idErrors"
          @input="$v.formData.collection_address_id.$touch()"
          @blur="$v.formData.collection_address_id.$touch()"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>

      <!-- <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.warehouse_id"
          label="Delivery address"
          :items="serverData.warehouses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div> -->
      <div class="col-sm-6 col-12">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Collection date"
              readonly
              clearable
              dense
              outlined
              v-bind="attrs"
              v-on="on"
              :error-messages="collection_dateErrors"
              @input="$v.formData.collection_date.$touch()"
              @blur="$v.formData.collection_date.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.collection_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </div>

      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-select
          v-model="formData.vehicle_type_id"
          label="Vehicle type"
          :items="serverData.vehicle_types"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
        ></v-select>
      </div>
      <!-- <div class="col-12 col-sm-6 col-md-6 h-75px">
        <v-datetime-picker
          ref="datetime"
          label="Collection date and time"
          :textFieldProps="{
            outlined: true,
            dense: true,
            'prepend-icon': 'mdi-calendar',
            'error-messages': collection_datetimeErrors,
          }"
          :datePickerProps="{}"
          :timePickerProps="{}"
          v-model="formData.collection_datetime"
          @input="$v.formData.collection_datetime.$touch()"
          @blur="$v.formData.collection_datetime.$touch()"
        >
        </v-datetime-picker>
      </div> -->
      <div class="col-sm-6 col-md-4 col-12 h-75px">
        <v-select
          v-model="formData.package_type"
          label="Package type"
          item-text="text"
          item-value="index"
          :items="serverData.package_types"
          clearable
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-text-field
          v-model="formData.number_of_packages"
          :label="number_of_packages_label"
          type="number"
          min="0"
          clearable
          outlined
          dense
          @change="() => validateMinValue('number_of_packages', 0)"
        ></v-text-field>
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 h-75px"
        v-if="formData.package_type == '1'"
      >
        <v-text-field
          v-model="formData.total_number_of_boxes"
          label="Total number of boxes"
          type="number"
          min="0"
          clearable
          outlined
          dense
          @change="() => validateMinValue('total_number_of_boxes', 0)"
        ></v-text-field>
      </div>

      <div
        class="col-12 col-sm-6"
        :class="{ 'col-md-4': formData.package_type === 1 }"
      >
        <v-text-field
          class
          v-model="formData.weight"
          type="number"
          min="0"
          label="Weight"
          clearable
          outlined
          dense
          @change="() => validateMinValue('weight', 0)"
        ></v-text-field>
      </div>
      <div
        class="col-12 col-sm-6"
        :class="{ 'col-md-4': formData.package_type === 1 }"
      >
        <v-text-field
          class
          v-model="formData.volumetric_weight"
          type="number"
          min="0"
          label="Volumetric weight"
          clearable
          outlined
          dense
          @change="() => validateMinValue('volumetric_weight', 0)"
        ></v-text-field>
      </div>
      <div class="col-12">
        <v-text-field
          class
          v-model="formData.comment"
          label="Comments"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <CollectionAddress
        ref="collectionAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
        :customer_id="customer_id"
        :getStep2Data="getStep2Data"
      ></CollectionAddress>
    </form>
    <div class="mt-1 col-12 mb-4 elevation-0">
      <div class="col-8 col-sm-7 row justify-content-between pl-5">
        <!--        <v-btn class="mx-1" @click="goBack"> back </v-btn>-->
        <button class="btn btn-light mx-3 px-5 py-3 ls1" @click="goBack">
          Back
        </button>
        <button
          type="submit"
          class="btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <!--        <v-btn class="ml-1 mr-sm-6" color="primary" @click="submitCreateForm">-->
        <!--          submit-->
        <!--        </v-btn>-->
        <!-- <v-btn class="mx-1" color="red" dark @click="resetCreateForm">
        clear
      </v-btn> -->
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import CollectionAddress from "@/own/components/shipping/pickupOrders/addressComponents/CollectionAddress";
import Swal from "sweetalert2";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "CrossDockForm",
  mixins: [validationMixin, fieldValueValidation],
  components: { CollectionAddress },
  props: [
    "setData",
    "actionFuntion",
    "serverData",
    "pageLoader",
    "initData",
    "userId",
    "customer_id",
    "getStep2Data",
  ],
  validations() {
    return {
      formData: {
        destination_address_type: { required },
        collection_date: { required },
        order_ids: { required },
        // vehicle_type_id: { required },
        collection_address_id: { required },
        warehouse_id: {
          required: requiredIf(function () {
            return this.formData.destination_address_type === 1;
          }),
        },
        destination_country_id: {
          required: requiredIf(function () {
            return this.formData.destination_address_type === 0;
          }),
        },
      },
    };
  },
  data: () => ({
    formData: {
      package_type: 2,
      number_of_packages: null,
      collection_date: null,
      order_ids: [],
      total_number_of_boxes: null,
      collection_address_id: null,
      warehouse_id: null,
      destination_country_id: null,
      weight: null,
      vehicle_type_id: null,
      volumetric_weight: null,
      comment: null,
      destination_address_type: 0,
    },
    menu1: false,
    search: null,
    count: null,
    entries: [],
    isLoading: false,
    doneTypingInterval: 1500,
    typingTimer: null,
    collection_addresses: [],
  }),
  beforeMount() {
    this.collection_addresses = this.serverData.collection_addresses;
    // console.log(this.serverData.destination_address_types);
    // this.formData.destination_address_type = this.destinationAddressType;
  },
  methods: {
    handleChangeMultiple(val) {
      let seperated = [];
      val.forEach((element) => {
        let subElement = element.trim().split(/(?:,| )+/);
        subElement.forEach((sub) => {
          seperated.push(sub);
        });
      });
      this.formData.order_ids = seperated;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    createNewItem() {
      this.$nextTick(() => {
        this.$refs.collectionAddressComponent.toggleModal();
      });
    },
    async getData(value) {
      let data = { q: value };
      // if (this.userId) data.client = this.userId;
      if (this.customer_id) data.client = this.customer_id;

      await fetch(`${process.env.VUE_APP_BASE_URL}/orders/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.orders;
        })
        .finally(() => (this.isLoading = false));
    },
    // setSkus(item) {
    //   this.formData.order_ids = [...item];
    // },
    goBack() {
      this.actionFuntion("back");
    },
    setDataOfAddress(data, type) {
      this.formData[`${type}`] = data;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.formData.order_ids.length) {
        Swal.fire({
          title: "Info",
          text: `Orders are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = {
        ...this.formData,
        order_ids: [...this.formData.order_ids],
      };
      const [year, month, day] = this.formData.collection_date.split("-");
      data.collection_date = `${day}-${month}-${year}`;
      // data.collection_datetime = this.dateFormatter(
      //   this.formData.collection_datetime
      // );
      // console.log(data);
      // let orders = data.order_ids.map((order) => {
      //   return order.orderId;
      // });
      // data.order_ids = orders;
      // data.collection_address_id = this.formData.collection_address_id.index;
      // console.log("data", data);
      this.setData(data);
      this.actionFuntion();
      // this.resetCreateForm();
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      this.collection_addresses = this.serverData.collection_addresses;

      if (this.initData) {
        // try {
        let copy = { ...this.serverData.pickup_order };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.pickup_order).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });

          if (
            this.serverData.pickup_order.collection_date &&
            this.serverData.pickup_order.collection_date != ""
          ) {
            const [day, month, year] =
              this.serverData.pickup_order.collection_date.split("-");
            this.formData.collection_date = `${year}-${month}-${day}`;
          }
          // this.$nextTick(() => {

          // });
        });
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // } catch (e) {
        // console.error(e);

        // this.formData = {
        //   package_type: null,
        //   number_of_packages: null,
        //   collection_datetime: null,
        //   order_ids: [],
        // };
        // }
      } else {
        // this.$nextTick(() => {
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // });
        this.formData = {
          package_type: 2,
          number_of_packages: null,
          collection_date: null,
          order_ids: [],
          total_number_of_boxes: null,
          collection_address_id: null,
          vehicle_type_id: null,
          warehouse_id: null,
          destination_country_id: null,
          weight: null,
          volumetric_weight: null,
          comment: null,
          destination_address_type: 0,
        };
        // this.$refs.datetime.clearHandler();
      }
    },
    dateMaker(data) {
      let date = new Date(data);
      return date;
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    onChangeOrders() {
      //validate
      this.$v.formData.order_ids.$touch();
      //set box number
      if (this.formData.package_type === 2) {
        this.formData.number_of_packages = this.formData.order_ids.length;
      }
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.formData.collection_date);
    },
    items: function () {
      return this.entries;
    },
    orderErrors: function () {
      return this.handleFormValidation("order_ids", this);
    },
    // warehouse_idErrors: function () {
    //   return this.handleFormValidation("warehouse_id", this);
    // },
    collection_address_idErrors: function () {
      return this.handleFormValidation("collection_address_id", this);
    },
    // vehicle_type_idErrors: function () {
    //   return this.handleFormValidation("vehicle_type_id", this);
    // },
    collection_dateErrors() {
      return this.handleFormValidation("collection_date", this);
    },
    destination_address_Errors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    destination_country_Errors: function () {
      return this.handleFormValidation("destination_country_id", this);
    },
    number_of_packages_label() {
      return this.formData.package_type == "2"
        ? "Number of boxes"
        : "Number of pallet";
    },
    destination_address_type_idErrors: function () {
      return this.handleFormValidation("destination_address_type", this);
    },
  },
  watch: {
    // formData: {
    //   handler(val) {
    //     console.log(val);
    //   },
    //   deep: true,
    // },
    // destination_address_type(val) {
    //   console.log("The colour has changed!", val);
    // },
    search(val) {
      clearTimeout(this.typingTimer);
      this.isLoading = true;
      if (val) {
        this.typingTimer = setTimeout(() => {
          this.getData(val.trim());
        }, this.doneTypingInterval);
      } else {
        this.isLoading = false;
        return;
      }
    },
  },
};
</script>
